import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/hohesalve',
    name: 'hohesalve',
    component: () => import(/* webpackChunkName: "hohesalve" */ '../views/cups/HoheSalve.vue')
  },
  {
    path: '/bozen',
    name: 'bozen',
    component: () => import(/* webpackChunkName: "bozen" */ '../views/cups/Bozen.vue')
  },
  {
    path: '/burgenland',
    name: 'burgenland',
    component: () => import(/* webpackChunkName: "burgenland" */ '../views/cups/Burgenland.vue')
  },
  {
    path: '/entdeckerviertel',
    name: 'entdeckerviertel',
    component: () => import(/* webpackChunkName: "entdeckerviertel" */ '../views/cups/Entdeckerviertel.vue')
  },
  {
    path: '/muerztal',
    name: 'muerztal',
    component: () => import(/* webpackChunkName: "muerztal" */ '../views/cups/Muerztal.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/images',
    name: 'images',
    component: () => import(/* webpackChunkName: "images" */ '../views/Images.vue'),
  },
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
