import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'ebike-cup',
  storage: localStorage
});

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    language: 'DE'
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    setLanguage({commit}, language) {
      commit('setLanguage', language);
    },
  },
  getters: {
    getLanguage: state => state.language,
  },
});
