import 'babel-polyfill';
import Vue from 'vue';
import i18next from "i18next";
import I18NextXhrBackend from "i18next-xhr-backend";
import VueI18Next from "@panter/vue-i18next";
import VueResource from 'vue-resource';
import * as moment from 'moment';
import 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueI18Next);
Vue.use(VueResource);

moment.locale('de');
moment.tz.setDefault('Europe/Vienna');
Vue.prototype.$moment = moment;

Vue.router = router;
Vue.http.options.root = 'https://yhcbe4jzz8.execute-api.eu-central-1.amazonaws.com/prod';

i18next.use(I18NextXhrBackend).init({
  fallbackLng: 'DE',
  lng: store.getters.getLanguage,
  backend: {
    loadPath: '/locales/{{lng}}.json'
  },
});

const i18n = new VueI18Next(i18next);

Vue.prototype.globalEvents = new Vue();

router.afterEach(() => {
  Vue.prototype.globalEvents = new Vue();
  Vue.nextTick(() => {
    window.scrollTo(0, 0);
  });
});

/* ********************************************
 *    LAZY LOADING DIRECTIVE
 * ******************************************** */
const config = {
  rootMargin: '150px 150px 150px 150px',
  threshold: 0.01,
};
const lazyLoadImage = (el, src) => {
  /* eslint-disable no-new */
  /* eslint-disable no-param-reassign */
  if ('IntersectionObserver' in window) {
    const oberver = new IntersectionObserver((entries) => {
      if (entries.length > 1 && entries[1].intersectionRatio > 0) {
        const img = new Image();
        img.onload = () => {
          entries[0].target.style.cssText = null;
          el.src = src;
        };
        img.src = src;
        oberver.disconnect();
      } else if (entries[0].intersectionRatio > 0) {
        const img = new Image();
        img.onload = () => {
          entries[0].target.style.cssText = null;
          el.src = src;
        };
        img.src = src;
        oberver.disconnect();
      }
    }, config);
    oberver.observe(el);
  } else {
    const img = new Image();
    img.onload = () => {
      el.src = src;
    };
    img.src = src;
  }
};
Vue.directive('lazyimg', {
  bind(el, binding) {
    lazyLoadImage(el, binding.value);
  },
  update(el, binding) {
    if (binding.value !== binding.oldValue) {
      lazyLoadImage(el, binding.value);
    }
  },
});

new Vue({
  router: router,
  store: store,
  vuetify: vuetify,
  i18n: i18n,
  render: h => h(App)
}).$mount('#app');
